<template>
    <div class="app-body monthCard">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-b">推送列表</span>
            <div class="a-flex-rcc">
                <el-button icon="el-icon-plus" type="primary" @click="editPush()">添加推送</el-button>
            </div>
        </div>
        <el-card class="el-main">
            <le-search-form @reset="handlerRest" @search="handlerSearch" :canPackUp="true" id="leSearch">
                <le-input label="推送ID" v-model="pageParam.params.id" />
                <le-input label="推送名称" v-model="pageParam.params.title" />
            </le-search-form>
            <le-pagview ref="pushListPage" @setData="setTableData" @pageChange='pageChange' :pageParam="pageParam" :isFixed="true" :tableRef="$refs.pushList">
                <el-table ref="pushList" class="couponPagelist" :data="tableData" :highlight-current-row="true"  style="width: 100%" v-sticky="{ top: 0, parent:'.el-card__body' }">
                    <el-table-column prop="orderId" label="序号" width="60">
                        <template slot-scope="scope">
							<span>{{ scope.$index + 1 + pageSize*(pageNum-1) }}</span>
						</template>
					</el-table-column>
                    <el-table-column label="推送ID" prop="id" min-width="100"></el-table-column>
                    <el-table-column label="推送名称" prop="title" min-width="160"></el-table-column>
                    <el-table-column 
                        label="关联类型"
                        min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.relationType | initDic(relationTypeDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        label="推送"
                        min-width="120">
                        <template slot-scope="scope">
                            <el-switch
                                @change="changeStatus(scope.row,scope.$index)"
                                v-model="scope.row.pushState"
                                :inactive-value="0"
                                :active-value="1"
                                active-color="#409EFF"
                                inactive-color="#F56C6C">
                            </el-switch>
                            <span class="a-mlr-12" :class="scope.row.pushState==1?'a-c-blue':'a-c-error'">{{ scope.row.pushState==1?'已启用':'已停用' }}</span>
                        </template>
                    </el-table-column>
                    
                    <el-table-column prop="name" label="操作" width="200" fixed="right">
                        <template slot-scope="scope">
                            <el-button type="text" @click="handleMove(scope.row, 0)">置顶</el-button>
                            <el-button type="text" @click="handleMove(scope.row, 1)">上移</el-button>
                            <el-button type="text" @click="handleMove(scope.row, 2)">下移</el-button>
                            <el-button type="text" @click="removePush(scope.row)">删除</el-button>
                            <el-button type="text" @click="editPush(scope.row)">编辑</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <template slot="footerLabel" slot-scope="scope" >
                    <div class="a-flex-rfsc" v-if="scope.val != -1">
                        <span class="a-fs-12 a-c-normal">筛选出</span>
                        <span class="a-fs-16 a-c-master a-fw-b a-plr-7">{{scope.val}}</span>
                        <span class="a-fs-12 a-c-normal a-plr-10">条记录</span>
                    </div>
                </template>
            </le-pagview>
        </el-card>
    </div>
</template>

<script>
import util from '../../../src/utils/util'
    export default {
        data () {
            return {
                util: util,
                tableData: [],
                pageParam: {
                    url: this.$Config.apiUrl.pushPage,
                    method: "post",
                    params: {
                        title: '',//
                        id: '', //id
                    },
                    freshCtrl: 1,
                },
                pageSize: 20,
                pageNum: 1,
                relationTypeDic: [],
            }
        },
        created () {
            this.$getDic('adRelationType').then(res=>{ this.relationTypeDic = res; })
            // this.$getDic('userJoinTimes').then(res=>{ this.userJoinTimesDic = res; })
            // this.$getDic('activityType').then(res=>{ this.activityTypeDic = res; })
            // this.$getDic('activityPeriod').then(res=>{ this.activityPeriodDic = res; })
            // this.$getDic('activityStatus').then(res=>{ this.statusStatusDic = res; })
        },
        activated () {
            this.pageParam.freshCtrl++;
        },  
        methods: {
            //获取列表
            setTableData(data) {
                this.tableData = data;
            },
            handlerRest() {
                this.pageParam.params = {
                    title: '',//
                    id: '', //id
                };
                this.handlerSearch()
            },
            pageChange (datas) {
                this.pageSize = datas.pageSize
                this.pageNum = datas.pageNum
            },
            handlerSearch() {
                this.$refs['pushListPage'].pageNum = 1
                this.pageParam.freshCtrl++;
            },

            handleMove (datas, type) {
                let typeDic = {
                    0: '是否确认置顶该推送？',
                    1: '是否确认上移该推送排序？',
                    2: '是否确认下移该推送排序？'
                }
                this.$confirm(typeDic[type],'温馨提示')
                .then(_=>{
                    this.$Axios._post({
                        url: this.$Config.apiUrl.pushMove,
                        method: "post",
                        params: {
                            id: datas.id,
                            moveType: type
                        }
                    }).then(({data}) => { 
                        this.$message.success('操作成功')
                        this.pageParam.freshCtrl++;
                    })
                })
                .catch(err=>{
                    console.log(err);
                })
            },

            removePush (datas) {
                this.$confirm('是否确认删除？','温馨提示')
                .then(_=>{
                    this.$Axios._post({
                        url: this.$Config.apiUrl.pushRemove,
                        method: "post",
                        params: {
                            id: datas.id,
                        }
                    }).then(({data}) => { 
                        this.$message.success('操作成功')
                        this.pageParam.freshCtrl++;
                    })
                })
                .catch(err=>{
                    console.log(err);
                })
            },

            changeStatus (datas) {
                this.$Axios._post({
                    url: this.$Config.apiUrl.pushStatusChange,
                    method: "post",
                    params: {
                        id: datas.id,
                        pushState: datas.pushState
                    }
                }).then(({data}) => { 
                    this.$message.success('操作成功')
                    this.pageParam.freshCtrl++;
                })
            },

            editPush (datas) {
                this.$router.push({
                    path: '/activity/push-edit',
                    query:{
                        id: datas&&datas.id?datas.id:''
                    }
                })
            },

        }
    }
</script>

<style lang="scss" scoped>
.couponPagelist{
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
        /*width: 0;宽度为0隐藏*/
        width: 0 !important;
        height: 0 !important;
    }
}

/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
    height: 100% !important;
}
.stationTag{
    padding: 0px 6px;
    background: #f4f4f5;
    color: #909399;
    border: 1px solid #e9e9eb;
    border-radius: 3px;
    margin-right: 4px;
    margin-bottom: 4px;
    font-size: 12px;
    word-break: keep-all;
}
</style>